import React from 'react';
import s from "./styles.module.scss";
import OutlineButton from "../../../../components/ui/OutlineButton/OutlineButton";

const NftPageInfoButtonsBlock = ({variant, buttons, disabled = false, url, newTab}) => {

  return (
    <div className={`${s.infoButtons} 
      ${variant === 'bot' ? s.infoButtons_bot : ''}
      ${variant === 'land' ? s.infoButtons_land : ''}
      ${variant === 'landPage' ? s.infoButtons_landPage : ''}`
    }>
      <div className={s.infoButtons__list}>
        {
          buttons.map(button => <a className={button?.disabled ? `coming-soon-item ${s.disabledLink}` : ''} rel="noreferrer" target={button.selfOpen ? '_self' : '_blank'} href={button.url}>{button.title}</a>)
        }

      </div>
      {/*{*/}
      {/*  (variant === 'land' || variant === 'landPage')*/}
      {/*    ?*/}
      {/*    <div>*/}
      {/*      /!* eslint-disable-next-line no-script-url *!/*/}
      {/*      <a href={url ? url : variant === '/landPage' ? 'javascript:void(0)' : '/landsNft'}*/}
      {/*        className={`${variant === 'land' ? `black ${s.landNftButton}` : s.buyBattleSet } */}
      {/*                    button-type1  */}
      {/*                    ${s.landButton}*/}
      {/*                    ${disabled ? s.disabled : ''}*/}
      {/*                    `}*/}
      {/*         rel="noreferrer" target={newTab ? '_blank' : '_self' }*/}
      {/*      >*/}
      {/*        {variant === 'land' && <span className="button-type1-before" />}*/}
      {/*        <span className="label">{variant === 'landPage' ? 'Buy Lands' : 'Learn more'}</span>*/}
      {/*        {variant === 'land' && <span className="button-type1-after" />}*/}
      {/*      </a>*/}
      {/*    </div>*/}

      {/*    :*/}
      {/*    <OutlineButton href={variant === 'bot' ? '/fightingBots' : variant === 'landPage' ? '/#' : '/botsNft  '}*/}
      {/*                   variant={'selfOpen'}*/}
      {/*                   text={variant === 'landPage' ? 'Buy Battle Set' : 'Learn more'}*/}
      {/*    />*/}
      {/*}*/}

    </div>
  );
}

export default NftPageInfoButtonsBlock;