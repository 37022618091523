import React, { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { useDimensions } from "hooks/useDimensions";
import logo from "Image/game_logo.png";
import "./index.scss";
import {items} from "./HeaderItems";
import MenuItem from "./MenuItem";
import {config} from "../../utils/config";


const Header = ({ setIsForceOpen, isForceOpen }) => {
  const location = useLocation().pathname;
  const { height, width } = useDimensions();
  const [openMenuIndex, setOpenMenuIndex] = useState(null)
  // const {account, isCorrectNetwork, connect} = useContext(LandSaleContext)


  useEffect(() => {
    items.forEach((menuItem, index) => {

      if(menuItem.items.find(item => item.url === location)){
        setOpenMenuIndex(index)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <header
      className={`header js-header ${isForceOpen ? "open" : ""}`}
      style={{ height: width < 1024 ? height : "100%" }}
    >
      <div className="header-bg" />
      <div className="header-bg-image" />
      <div className="header-container">
        <a className="header-logo" href="/" rel="noreferrer">
          <img
            src={logo}
            alt="Join the Rebel Bots resistance"
            title="Join the Rebel Bots resistance"
            importance="high"
          />
        </a>
        <div
          className="header-popup"
          style={{ height: width < 1024 ? height : "100%" }}
        >
          <nav className="header-menu"  >
            <div className="header-menu_wrapper">

              {
                items.map((menuItem, index) => (
                  <MenuItem openMenuIndex={openMenuIndex}
                            key={menuItem.title}
                            index={index}
                            {...menuItem}
                            location={location}
                            setOpenMenuIndex={setOpenMenuIndex} />
                  )
                )
              }

              {/*<a rel={'noreferrer'} target={'_blank'} href={config.MARKETPLACE_LINK} className="header-menu__item js-header-menu-item">*/}
              {/*  <span>marketplace</span>*/}
              {/*</a>*/}

            </div>
          </nav>
          <aside className="header-right">
            {/*<div onClick={ connect } className='connect'>*/}

            {/*  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*    <path d="M17.3245 5.19957C17.3245 4.34234 16.6367 3.65457 15.7795 3.65457H2.95101C2.42272 3.65457 1.9044 3.13625 1.9044 2.62789C1.9044 2.10957 2.42272 1.60122 2.95101 1.60122H15.1914C15.4804 1.60122 15.7097 1.83048 15.7097 2.11954V2.63786H15.7197C16.0785 2.63786 16.4274 2.7176 16.7364 2.84718V2.11954C16.7364 1.25235 16.0386 0.564575 15.1914 0.564575H3.4793C0.887695 0.564575 0.887695 2.62789 0.887695 2.62789V13.1837C0.887695 14.4197 2.24331 15.5062 3.4793 15.5062H15.7795C16.6367 15.5062 17.3245 14.8184 17.3245 13.9612V11.3895H13.7162C12.8589 11.3895 12.1712 10.7017 12.1712 9.84453C12.1712 8.9873 12.8589 8.29953 13.7162 8.29953H17.3245V5.19957Z" fill="#FD8D28"/>*/}
            {/*    <path d="M14.2342 10.8612C14.8012 10.8612 15.2609 10.4016 15.2609 9.83454C15.2609 9.26752 14.8012 8.80786 14.2342 8.80786C13.6672 8.80786 13.2075 9.26752 13.2075 9.83454C13.2075 10.4016 13.6672 10.8612 14.2342 10.8612Z" fill="#FD8D28"/> <path d="M14.7928 2.1095H3.45947V3.13617H14.7928V2.1095Z" fill="#984800"/></svg> */}


            {/*  {*/}
            {/*    isCorrectNetwork && account*/}
            {/*      ? account.substring(0, 8) + '...' + account.substring(account.length - 4, account.length)*/}
            {/*      : 'Connect Wallet'*/}
            {/*  }*/}
            {/*</div>*/}
            <a href='/play' className='play-button'>
              Play
            </a>
          </aside>
        </div>
        <button
          {...(typeof setIsForceOpen === "function" && {
            onClick: () => setIsForceOpen((prev) => !prev),
          })}
          className="header-hamburger js-header-hamburger"
        >
          <span />
        </button>
      </div>
    </header>
  );
};


export default Header;
