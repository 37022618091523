import tgIcon from '../../Image/telegram_iconM.svg'
import twiiterIcon from '../../Image/twitter_iconM.svg'
import discordIcon from '../../Image/discord_iconM.svg'
import openSeaIcon from '../../Image/opensea_iconM.svg'
import mediumIcon from '../../Image/mediumIconM.svg'


export const items = [
  {
    title: 'Game',
    items: [
      // {
      //   url: '/cardsExplorer',
      //   name: 'Card Explorer',
      // },
      {
        url: '/nft',
        name: 'NFTs',
      },
      // {
      //   url: '/botsNft',
      //   name: 'Rebel Bots',
      // },
      // {
      //   url: '/landsNft',
      //   name: 'Lands',
      // },
      // {
      //   url: '/fightingBots',
      //   name: 'Fighting Bots',
      // },
      // {
      //   url: '/battleSet',
      //   name: 'Buy Battle Set',
      // },
      {
        url: '/wiki',
        name: 'Game Guides',
      },
      // {
      //   url: '/guilds',
      //   name: 'Guilds',
      // },
      {
        url: 'https://medium.com/@Rebel_Bots',
        name: 'Blog',
        blank: true
      },
      {
        url: '/support',
        name: 'Support',
      },

    ]
  },
  {
    title: 'Community',
    items: [
      {
        url: 'https://rebel-bots.medium.com',
        name: 'Rebel Bots Origins',
        blank: true
      },
      // {
      //   url: '/profile',
      //   name: 'Rebel Bots Lore',
      // },
      // {
      //   url: '/leaderboard',
      //   name: 'Trading Contest',
      // },
      {
        url: 'https://medium.com/@Rebel_Bots',
        name: 'Blog',
        blank: true
      },
      {
        url: '/dao',
        name: 'DAO',
      },

    ]
  },
  {
    title: 'Company',
    items: [
      {
        url: '/whitepaper',
        name: 'Whitepaper',
      },
      {
        url: 'https://d2ir3791ijipum.cloudfront.net/rebel_bots_trademark_EU-US.pdf',
        name: 'Trademark',
        blank: true
      },
      // {
      //   url: '/careers',
      //   name: 'Careers',
      // },
      {
        url: '/contact',
        name: 'Contact',
      },
      {
        url: '/roadmap',
        name: 'Roadmap',
      },

    ]
  },
  {
    title: 'TOKEN',
    items: [
      {
        url: '/rbls',
        name: 'RBLS',
      },
      {
        url: '/xoil',
        name: 'XOIL',
      },
    ]
  },
  {
    title: 'Connect',
    items: [

      {
        url: 'https://twitter.com/REBEL_BOTS',
        name: 'Twitter',
        blank: true,
        icon: twiiterIcon
      },
      {
        url: 'https://discord.gg/rebel-bots',
        name: 'Discord',
        blank: true,
        icon: discordIcon
      },
      {
        url: 'https://medium.com/@Rebel_Bots',
        name: 'Medium',
        blank: true,
        icon: mediumIcon
      },
      {
        url: 'https://t.me/RebelBotsXoilWars',
        name: 'Telegram',
        blank: true,
        icon: tgIcon
      },
      {
        url: 'https://opensea.io/collection/rebelbots',
        name: 'OpenSea',
        blank: true,
        icon: openSeaIcon
      },
    ]
  },
]
