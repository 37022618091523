import React from 'react';
import s from './styles.module.scss'
import NftBotPageImage from '../../../../Image/nftBotPageImage.png'
import NftBotPageImageMobile from '../../../../Image/nftBotPageImageMobile.png'
import NftPageInfoButtonsBlock from "../NftPageInfoButtonsBlock/NftPageInfoButtonsBlock";
import {useDimensions} from "../../../../hooks/useDimensions";
import {config} from "../../../../utils/config";

const NftPageBots = () => {
  const {width} = useDimensions()
  const infoButton = [
    {title: 'OpenSea', url: 'https://opensea.io/collection/rebel-bots-fighting-bots'},
    {title: 'Contract', url: 'https://polygonscan.com/token/0x17892c8c3eb60c144872c18f013626471c3658bf'},
  ]

  return (
    <div className={s.nftPageBots}>
      <div className={s.nftPageBots__botImageContainer}>
        <img src={width > 1024 ? NftBotPageImage : width > 576 ? NftBotPageImage : NftBotPageImageMobile} alt="bot"/>
      </div>
      <div className={s.nftPageBots__content}>
        <h3 className='web1'>
          fighting {width > 576 && <br/>}   bots
        </h3>
        <p>Fighting Bots form your army which you build and command to battle and earn.
          Bots have 5 Parts, each with a card to create your battle deck. With 3 classes,
          300 cards, and powerful passive skills, combinations are limitless.
          <NftPageInfoButtonsBlock variant={'bot'} buttons={infoButton} />
        </p>

      </div>
    </div>
  );
}

export default NftPageBots;